import React, { useState } from "react";
import style from "./checkout.module.scss";
import CommonModal from "../../UI/CommonModal/CommonModal";
import { Checkbox, Input } from "antd";
import API from "../../Api/api";
import { antdMessageError, antdMessageSuccess, detectCardType } from "./utills";

export const AddCardDetails = ({
  setIsModalOpen,
  isModalOpen,
  setRefreshData,
  refreshData,
}: {
  setIsModalOpen: any;
  isModalOpen: any;
  setRefreshData: any;
  refreshData: any;
}) => {
  const [card, setcard] = useState({
    cardExpiry: "",
    cardNumber: "",
    cardType: "",
    nameOnCard: "",
    securityCode: "",
  });
  async function savecardDetails() {
    if (card.nameOnCard === '') {
      return antdMessageError('Please Provide Card Holder Name')
    } else if (card.cardNumber === '') {
      return antdMessageError('Please Provide Card Number')
    } else if (card.cardExpiry === '') {
      return antdMessageError('Please Provide Card Expiry')
    } else if (card.securityCode === '') {
      return antdMessageError('Please Provide Security Code')
    }
    try {
      const clientId = new URLSearchParams(window.location.search).get("clientId") || "";
      const monthAndYear = card?.cardExpiry?.split("/")
      const data = await API.payment.addCardDetails({
        clientId,
        cardExpiryMonth: monthAndYear?.[0],
        cardExpiryYear: `20${monthAndYear?.[1]}`,
        cardNumber: card.cardNumber.replaceAll(/\s/g, ""),
        nameOnCard: card.nameOnCard,
        cardType: card.cardType,
        securityCode: card.securityCode,
      });
      if (API.validators.checkSuccessCode(data)) {
        antdMessageSuccess("Card Details Added Successfully");
        setIsModalOpen({ ...isModalOpen, cardDetailsModal: false })
        setRefreshData(!refreshData)
        setcard({
          cardExpiry: "",
          cardNumber: "",
          cardType: "",
          nameOnCard: "",
          securityCode: "",
        })
      }
    } catch (error: any) {
      antdMessageError(error?.response?.data?.message);
    }
  }

  const handleExpiryDateChange = (e: any) => {
    let input = e.target.value;
    input = input.replace(/[^\d/]/g, "");
    if (input.length === 2 && !input.includes("/")) {
      input = input + "/";
    }
    if (input.length > 5) {
      input = input.slice(0, 5);
    }
    setcard({ ...card, cardExpiry: input });
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const onlyNumbers = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedNumber = onlyNumbers.replace(/(.{4})/g, "$1 ").trim(); // Format as 'XXXX XXXX XXXX XXXX'
    card.cardNumber = formattedNumber;
    card.cardType = detectCardType(onlyNumbers);
    setcard({ ...card }); // Update card number
  };

  console.log(card, 'asdfhahjghjsdfasfd');

  return (
    <CommonModal
      isModalVisible={() =>
        setIsModalOpen({ ...isModalOpen, cardDetailsModal: false })
      }
      visible={isModalOpen?.cardDetailsModal}
      centered
    >
      <div style={{ padding: "20px" }}>
        <div className={style["add-card-heading"]}>Add Card Details</div>
        <div className={style["card-form-container"]}>
          <div style={{ marginBottom: "16px" }}>
            <div className={style["label-name"]}>Full Name</div>
            <div className={style["first-name-input"]}>
              <Input
                onChange={(e) => {
                  setcard({ ...card, nameOnCard: e.target.value });
                }}
                value={card?.nameOnCard}
                placeholder="Full Name on the Card"
              />
            </div>
          </div>
          <div style={{ marginBottom: "16px" }}>
            <div className={style["label-name"]}>Card Number</div>
            <div className={style["first-name-input"]}>
              <Input
                value={card.cardNumber}
                onChange={handleCardNumberChange}
                placeholder="Card Number"
                maxLength={19}
              />
            </div>
          </div>
          <div className={style["expiration-date"]}>
            <div style={{ width: "80%" }}>
              <div className={style["label-name"]}>Expiration Date</div>
              <div className={style["cvv-input"]}>
                <Input
                  onChange={handleExpiryDateChange}
                  placeholder="MM/YY"
                  max={5}
                  value={card?.cardExpiry}
                />
              </div>
            </div>
            <div style={{ width: "20%" }}>
              <div className={style["label-name"]}>CVV</div>
              <div className={style["cvv-input"]}>
                <Input
                  onChange={(e) => {
                    setcard({ ...card, securityCode: e.target.value });
                  }}
                  placeholder="CVV"
                  value={card?.securityCode}
                  maxLength={3}
                  style={{ textAlign: "center" }}
                />
              </div>
            </div>
          </div>
          <div className={style["save-card"]}>
            <Checkbox />
            <div>Save card details with CyberSource</div>
          </div>
        </div>
        <div className={style["button-container"]}>
          <button className={style["discard-btn"]}
            onClick={() => {
              setIsModalOpen({ ...isModalOpen, cardDetailsModal: false })
              setcard({
                cardExpiry: "",
                cardNumber: "",
                cardType: "",
                nameOnCard: "",
                securityCode: "",
              })
            }}>Discard</button>
          <button
            onClick={() => {
              savecardDetails();
            }}
            className={style["save-btn"]}
          >
            Save
          </button>
        </div>
      </div>
    </CommonModal>
  );
};
