import React, { useContext, useEffect, useState } from "react";
import style from "../../component/Thankyou/alreadypurchased.module.scss";
import modalStyle from "./Modal.module.scss";
import Loader from "../loader";
import API from "../../Api/api";
import { MyContext } from "../../App";
import { Footer } from "../Checkout/Footer";
import { razorPayRedirectToContent } from "../utills/mobilehandlers";

const defaultPurchasedSubscriptionDetails = {
  purchasedDate: "",
  validity: "",
  offerDetails: [{ title: "", benefits: "", iconUrl: "" }],
  priceDetails: {
    amount: 0,
    currency: "",
  },
  subscription: {
    tierId: "",
    digital: false,
    physical: false,
    title: "",
    renew: false,
    duration: 0,
    durationText: "",
  },
  subscriptionDetails: {
    benefits: "",
  } as Record<string, any>,
};
function Alreadypurchased({ userDetails }: { userDetails: any }) {
  const searchParams = new URLSearchParams(window.location.search);
  let clientId = searchParams.get("clientId") || "";
  let tierId = searchParams.get("tierId") || "";
  let clientContentId = searchParams.get("clientId") || "";
  // const { clientId, tierId, clientContentId } = router.query;
  const [purchasedSubscriptionDetails, setPurchasedSubscriptionDetails] =
    useState(defaultPurchasedSubscriptionDetails);
  const [openModal, setCloseModal] = useState(false);
  const [toggle, setToggle] = useState("benefits");
  const [loading, setLoading] = useState(true);
  const { buttonBackground, buttonFontColor, brandLogoUrl, tnc, privPolicy } =
    useContext(MyContext);
  const accessToken = localStorage.getItem("accessToken") || "";
  const arrayToken = accessToken.split(".");
  const tokenPayload = JSON.parse(atob(arrayToken[1]));

  const SubscriptionAccessCheckResponse = async () => {
    const response = await API.subscription.SubscriptionAccessCheck({
      clientId: clientId as string,
      tierId: tierId as string,
    });
    if (API.validators.checkSuccessCode(response)) {
      setPurchasedSubscriptionDetails(
        response?.data?.purchasedSubscriptions?.[0]
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    SubscriptionAccessCheckResponse();
  }, [clientId, tierId]);
  const redirectToContent = async () => {
    razorPayRedirectToContent("SUBS_REDIRECT_TO_CONTENT", tokenPayload.sub);
    const searchParams = new URLSearchParams(window.location.search);
    let rid = searchParams.get("rid");
    if (rid === "null") {
      rid = null;
    }
    const redirectUrlResponse = await API.client.RedirectUserToContent({
      clientId: clientId as string,
      clientContentId: clientContentId as string,
      pageType: rid ? "AMP" : "STANDARD",
    });
    if (!API.validators.checkSuccessCode(redirectUrlResponse)) {
      return alert("Failed to load redirect URL");
    } else {
      const ssoUrl = redirectUrlResponse.data?.ssoRedirectUrl;
      const redirecturl = new URL(
        decodeURIComponent(redirectUrlResponse.data?.redirectUrl)
      );
      const anonId = localStorage.getItem("anonId") || "";
      const paywallId = localStorage.getItem("paywallId") || "";
      window.location.href = `${ssoUrl}?redirectUrl=${redirecturl}&clientId=${clientId}&anonId=${anonId}&contentId=${clientContentId}&paywallId=${paywallId}`;
    }
  };

  const handleBackgroundClick = (event: any, type?: string) => {
    if (event.target === event.currentTarget) {
      setCloseModal(false); // Close modal if background is clicked
      document.body.style.overflow = "auto";
    }
  };

  const closeModal = (value: boolean) => {
    setCloseModal(value);
    if (value) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  if (loading) return <Loader message="Getting Subscription data" />;

  return (
    <>
      {openModal && (
        <div
          className={modalStyle["modal-overlay"]}
          onClick={(event) => handleBackgroundClick(event)}
        >
          <div className={modalStyle["modal"]}>
            <img
              src="/modal_cross_icon.svg"
              alt=""
              className={modalStyle["close-btn"]}
              onClick={() => closeModal(false)}
            />
            <div className={modalStyle["header"]}>
              {purchasedSubscriptionDetails?.subscriptionDetails?.benefits?.split(
                ","
              )?.length > 0 && (
                <div
                  className={
                    toggle === "benefits"
                      ? modalStyle["header-Active"]
                      : modalStyle["header-Inactive"]
                  }
                  onClick={() => {
                    setToggle("benefits");
                  }}
                >
                  Benefits
                </div>
              )}
              {purchasedSubscriptionDetails?.offerDetails?.length > 0 && (
                <div
                  className={
                    toggle === "offers"
                      ? modalStyle["header-Active"]
                      : modalStyle["header-Inactive"]
                  }
                  onClick={() => setToggle("offers")}
                >
                  Exclusive Offers
                </div>
              )}
            </div>
            <div className={modalStyle["benefit"]}>
              {toggle === "benefits" ? (
                <div className={modalStyle["benefits"]}>
                  {purchasedSubscriptionDetails?.subscriptionDetails?.benefits
                    ?.split(",")
                    ?.map((key: any, index: any) => (
                      <div className={modalStyle["benefit-list"]} key={index}>
                        <img src="/tick.svg" alt="tick" />
                        <div>{key}</div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className={modalStyle["benefits"]}>
                  {purchasedSubscriptionDetails?.offerDetails?.map(
                    (
                      offer: {
                        title: string;
                        iconUrl: string;
                        benefits: string;
                      },
                      index: number
                    ) => (
                      <div key={index} className={style["individual-offer"]}>
                        <img
                          className={style["offer-img"]}
                          src={offer.iconUrl}
                          alt="offer-img"
                        />
                        <div className={style["new-offer-text"]}>
                          {offer.benefits}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={style["main-container"]}>
        <div className={style["header-logo"]}>
          <img className={style["image-logo"]} src={brandLogoUrl} alt="Logo" />
        </div>
        <div className={style["p-4"]}></div>
        <div className={style["sub-container"]}>
          <div className={style["innner-container"]}>
            <div className={style["title"]}>
              You already have an Active Subscription
            </div>
            <div className={style["details-container"]}>
              <div className={style["email-container"]}>
                Dear{" "}
                <span style={{ color: buttonBackground || "#000000" }}>
                  {userDetails?.email}
                </span>{" "}
                <br />
                {(purchasedSubscriptionDetails?.subscription?.title ||
                  purchasedSubscriptionDetails?.validity) && (
                  <span> Here are your plan details:</span>
                )}
              </div>
              <div className={style["plan-container"]}>
                {purchasedSubscriptionDetails?.subscription?.title && (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", textAlign: "end" }}>
                      Plan Name :{" "}
                    </div>
                    <div className={style["bold"]}>
                      {purchasedSubscriptionDetails?.subscription?.title}
                    </div>
                  </div>
                )}
                {purchasedSubscriptionDetails?.validity && (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", textAlign: "end" }}>
                      Expires / Due on :{" "}
                    </div>
                    <div className={style["bold"]}>
                      {purchasedSubscriptionDetails?.validity}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <button
              className={style["submitBtn"]}
              onClick={() => redirectToContent()}
              style={{
                background: buttonBackground || "#000000",
                color: buttonFontColor || "#ffffff",
                marginTop: "0",
                border: "0",
              }}
            >
              Continue Reading
            </button>
          </div>
        </div>
        <div className={style["footer"]}>
          <Footer termsAndConditions={tnc} privacyAndPolicy={privPolicy} />
        </div>
      </div>
    </>
  );
}

export default Alreadypurchased;
